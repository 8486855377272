import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../../helpers'
import {useAuth} from '../../core/auth'
import { userUpdatePassword } from "../../core/users/_request";

const requestPasswordSchema = Yup.object().shape({
  current_password: Yup.string().required("L'ancien mot de passe est requis"),
  new_password: Yup.string()
    .min(6, 'Minimum 6 caractères')
    .max(50, 'Maximum 50 caractères')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      'Le mot de passe doit contenir au moins 1 Lettre majuscule, 1 chiffre, et 1 caractère spécial'
    )
    .required('Le nouveau mot de passe est requis'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('new_password'), null], 'Les mots de passe sont différents')
    .required('La confirmation du mot de passe est requise '),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const navigate = useNavigate()
  const {saveAuth} = useAuth()

  const initialValues = {
    current_password: '',
    new_password: '',
    confirm_password: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: requestPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      try {
        const {status, result} = await userUpdatePassword(values)
        if (status === 'success') {
          saveAuth(undefined)
          navigate('/auth')
          setHasErrors(false)
          setLoading(false)
          window.location.reload()
        } else {
          setHasErrors(true)
          setLoading(false)
          setSubmitting(false)
          setStatus('Echec de réinitialisation du mot de passe')
        }
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('Les informations de connexion sont incorrectes')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <>
      <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
        <div
          className='d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative'
          style={{backgroundColor: '#f86812', width: '100%'}}
        >
          <div className='d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y'>
            <div className='d-flex flex-row-fluid flex-column text-center pt-lg-20'>
              <a href='#' className='mb-12'>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/fyle_community.png')}
                  className='h-45px'
                />
              </a>
              <h1 className='fw-bolder fs-2qx pb-5 pb-md-10' style={{color: '#0e0e0e'}}>
                Bienvenue sur My Community
              </h1>
              {/*<p className='fw-bold fs-2 ' style={{color: '#070707'}}>*/}
              {/*  Discover Amazing Metronic*/}
              {/*  <br />*/}
              {/*  with great build tools*/}
              {/*</p>*/}
              <div
                className='d-flex flex-row-auto bgi-no-repeat bgi-position-x-center< bgi-position-y-bottom min-h-100px min-h-lg-800px'
                style={{
                  backgroundImage: `url(${toAbsoluteUrl('/media/myc/bg_cover.png')})`,
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className='d-flex flex-column flex-lg-row-fluid py-10'>
          <div className='d-flex flex-center flex-column flex-column-fluid'>
            <a href='#' className='mb-12'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/fyle_community.png')}
                className='h-45px'
              />
            </a>
            {/*end::Logo*/}
            {/*begin::Wrapper*/}
            <div className='w-lg-500px p-10 p-lg-15 mx-auto'>
              <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='reset_password_form'
                onSubmit={formik.handleSubmit}
              >
                <div className='text-center mb-10'>
                  <h1 className='text-dark mb-3'>Réinitialiser le mot de passe</h1>
                  <div className='text-gray-400 fw-bold fs-4'>
                    Entrez l'ancien mot de passe et le nouveau mot de passe.
                  </div>
                </div>

                {hasErrors === true && (
                  <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>
                      Désolé, il y a des erreurs détectées. Réessayez encore !.
                    </div>
                  </div>
                )}

                {hasErrors === false && (
                  <div className='mb-10 bg-light-info p-8 rounded'>
                    <div className='text-info'>Mot de passe réinitialisé avec succès!</div>
                  </div>
                )}

                <div className='fv-row mb-10'>
                  <label className='form-label fw-bolder text-gray-900 fs-6'>
                    Actuel mot de passe
                  </label>
                  <input
                    type='password'
                    autoComplete='off'
                    {...formik.getFieldProps('current_password')}
                    className={clsx(
                      'form-control form-control-lg',
                      {
                        'is-invalid':
                          formik.touched.current_password && formik.errors.current_password,
                      },
                      {
                        'is-valid':
                          formik.touched.current_password && !formik.errors.current_password,
                      }
                    )}
                  />
                  {formik.touched.current_password && formik.errors.current_password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.current_password}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='fv-row mb-10'>
                  <label className='form-label fw-bolder text-gray-900 fs-6'>
                    Nouveau mot de passe
                  </label>
                  <input
                    type='password'
                    autoComplete='off'
                    {...formik.getFieldProps('new_password')}
                    className={clsx(
                      'form-control form-control-lg',
                      {
                        'is-invalid': formik.touched.new_password && formik.errors.new_password,
                      },
                      {
                        'is-valid': formik.touched.new_password && !formik.errors.new_password,
                      }
                    )}
                  />
                  {formik.touched.new_password && formik.errors.new_password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.new_password}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='fv-row mb-10'>
                  <label className='form-label fw-bolder text-gray-900 fs-6'>
                    Confirmez le mot de passe
                  </label>
                  <input
                    type='password'
                    autoComplete='off'
                    {...formik.getFieldProps('confirm_password')}
                    className={clsx(
                      'form-control form-control-lg',
                      {
                        'is-invalid':
                          formik.touched.confirm_password && formik.errors.confirm_password,
                      },
                      {
                        'is-valid':
                          formik.touched.confirm_password && !formik.errors.confirm_password,
                      }
                    )}
                  />
                  {formik.touched.confirm_password && formik.errors.confirm_password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.confirm_password}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                  <button
                    type='submit'
                    id='reset_password_submit'
                    className='btn btn-lg btn-active-light-sucess fw-bolder me-4'
                    style={{backgroundColor: '#f86812'}}
                  >
                    <span className='indicator-label'>Enregistrer</span>
                    {loading && (
                      <span className='indicator-progress'>
                        Veillez patientez...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                  <Link to='/auth/login'>
                    <button
                      type='button'
                      id='reset_password_cancel_button'
                      className='btn btn-lg btn-color-dark fw-bolder'
                      style={{backgroundColor: '#f86812', opacity: '0.3'}}
                      disabled={formik.isSubmitting || !formik.isValid}
                    >
                      Annuler
                    </button>
                  </Link>{' '}
                </div>
              </form>
            </div>

            {/*end::Wrapper*/}
          </div>
        </div>
      </div>
    </>
  )
}
