/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import {Outlet, Route, Routes} from 'react-router-dom'
import {ForgotPassword} from '../../components/auth/ForgotPassword'
import {Login} from '../../components/auth/Login'
import {toAbsoluteUrl} from '../../helpers'

const Carousel = () => {
  const images = [
    toAbsoluteUrl('/media/myc/bg_cover1.png'),
    toAbsoluteUrl('/media/myc/bg_cover2.png'),
    toAbsoluteUrl('/media/myc/bg_cover3.png'),
  ];

  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className='position-relative'>
      <div
        className='d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-position-y-bottom min-h-100px min-h-lg-500px'
        style={{
          backgroundImage: `url(${images[currentImage]})`,
          transition: 'background-image 1s ease-in-out',
        }}
      ></div>
      <div className='position-absolute bottom-0 w-100 text-center mb-3'>
        {images.map((_, index) => (
          <span
            key={index}
            className={`mx-1 ${
              index === currentImage ? 'bg-white' : 'bg-gray-500'
            }`}
            style={{ width: '10px', height: '5px', display: 'inline-block' }}
            onClick={() => setCurrentImage(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  const Title = () => {
    return (
      <h1 className='fw-bolder fs-2qx pb-2 pb-md-3' style={{color: '#f1f1f1'}}>
        Bienvenue sur le Back Office de My Community
      </h1>
    )
  }

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
      <div
        className='d-flex flex-column flex-lg-row-auto w-xl-800px positon-xl-relative'
        style={{backgroundColor: '#f86812', width: '100%'}}
      >
        <div className='d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-800px scroll-y'>
          <div className='d-flex flex-row-fluid flex-column text-center pt-lg-5'>
            <a href='#' className='mb-6'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/fyle_community.png')}
                className='h-100px'
              />
            </a>
            <Title />
            <Carousel />
          </div>
        </div>
      </div>
      <div className='d-flex flex-column flex-lg-row-fluid py-10'>
        <div className='d-flex flex-center flex-column flex-column-fluid'>
          <div className='w-lg-500px p-10 p-lg-15 mx-auto'>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
