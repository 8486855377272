/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {MixedWidget10, StatsWidget1} from '../../components/layout/contents/widgets'
import {PageTitle} from '../../core/layout'
import {TablesWidget9} from '../../components/layout/contents/widgets/tables/TablesWidget9'
import CamembertChart1 from '../../components/layout/contents/widgets/others/camember1'
import {StatCard} from '../../components/dashboard/GestionnaireDashboard'
import { Col, Row } from "antd";
import StatisticWidget from "../../components/common/StatisticWidget";

// const DashboardPage: FC = () => (
//   <>
//     <Row gutter={16}>
//       <Col xs={24} sm={24} md={24} lg={18}>
//         <Row gutter={16}>
//           {
//             annualStatisticData.map((elm, i) => (
//               <Col xs={24} sm={24} md={24} lg={24} xl={8} key={i}>
//                 <StatisticWidget
//                   title={elm.title}
//                   value={elm.value}
//                   status={elm.status}
//                   subtitle={elm.subtitle}
//                 />
//               </Col>
//             ))
//           }
//         </Row>
//         <Row gutter={16}>
//           <Col span={24}>
//             <ChartWidget
//               title="Unique Visitors"
//               series={visitorChartData.series}
//               xAxis={visitorChartData.categories}
//               height={'400px'}
//               direction={direction}
//             />
//           </Col>
//         </Row>
//       </Col>
//       <Col xs={24} sm={24} md={24} lg={6}>
//         <GoalWidget
//           title="Monthly Target"
//           value={87}
//           subtitle="You need abit more effort to hit monthly target"
//           extra={<Button type="primary">Learn More</Button>}
//         />
//         <StatisticWidget
//           title={
//             <MembersChart
//               options={memberChartOption}
//               series={activeMembersData}
//               height={145}
//             />
//           }
//           value='17,329'
//           status={3.7}
//           subtitle="Active members"
//         />
//       </Col>
//     </Row>
//     {/* end::Row */}
//   </>
// )

export const AnnualStatisticData = [
  {
    title: 'Entrées globale',
    value:'4 032 155',
    status: -11.4,
    subtitle: `Comparer a l\'année précédente (2023)`
  },
  {
    title: 'Sorties',
    value:'2 000 524',
    status: 8.2,
    subtitle: `Comparer a l\'année précédente (2023)`
  },
  {
    title: 'Organisation',
    value:'5',
    status: 0.7,
    subtitle: `Comparer a l\'année précédente (2023)`
  }
]

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const [annualStatisticData] = useState(AnnualStatisticData);

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <Row gutter={16}>
      <Col xs={24} sm={24} md={24} lg={18}>
        <Row gutter={16}>
          {
            annualStatisticData.map((elm, i) => (
              <Col xs={24} sm={24} md={24} lg={24} xl={8} key={i}>
                <StatisticWidget
                  title={elm.title}
                  value={elm.value}
                  status={elm.status}
                  subtitle={elm.subtitle}
                />
              </Col>
            ))
          }
        </Row>
      {/*  <Row gutter={16}>*/}
      {/*    <Col span={24}>*/}
      {/*      <ChartWidget*/}
      {/*        title="Unique Visitors"*/}
      {/*        series={visitorChartData.series}*/}
      {/*        xAxis={visitorChartData.categories}*/}
      {/*        height={'400px'}*/}
      {/*        direction={direction}*/}
      {/*      />*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*</Col>*/}
      {/*<Col xs={24} sm={24} md={24} lg={6}>*/}
      {/*  <GoalWidget*/}
      {/*    title="Monthly Target"*/}
      {/*    value={87}*/}
      {/*    subtitle="You need abit more effort to hit monthly target"*/}
      {/*    extra={<Button type="primary">Learn More</Button>}*/}
      {/*  />*/}
      {/*  <StatisticWidget*/}
      {/*    title={*/}
      {/*      <MembersChart*/}
      {/*        options={memberChartOption}*/}
      {/*        series={activeMembersData}*/}
      {/*        height={145}*/}
      {/*      />*/}
      {/*    }*/}
      {/*    value='17,329'*/}
      {/*    status={3.7}*/}
      {/*    subtitle="Active members"*/}
      {/*  />*/}
      </Col>
    </Row>
    </>
  )
}

export {DashboardWrapper}
