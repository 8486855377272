import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuPage} from '../pages/dashboard/MenuPage'
import {MasterLayout} from '../layouts/MasterLayout'
import {getCSSVariableValue} from '../assets/ts/_utils'
import AuditPage from '../pages/audit/AuditPage'
import {PageTitle} from '../core/layout'

const breadcrumbs = [
  {
    title: 'Tableau de bord',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Retour',
    path: '/dashboard',
    isSeparator: true,
    isActive: false,
  },
]

const PrivateRoutes = () => {
  const SettingPage = lazy(() => import('../pages/params'))
  const UsersPage = lazy(() => import('../pages/users'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registration */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route path='auth/forgot-password' element={<Navigate to='/auth/forgot-password' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='menu-pages' element={<MenuPage />} />
        {/* Lazy Modules */}
        <Route
          path='/apps/utilisateurs/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/parametres/*'
          element={
            <SuspensedView>
              <SettingPage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/audit-app'
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Audit APP</PageTitle>
              <AuditPage />
            </>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
