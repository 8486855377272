import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }

  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #f86812;
    border-color: #f86812;
  }

  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background: #1f1f7d;
    border-color: #1f1f7d;
  }

  .table_hospit_attente ant-table-thead {
    background: red;
  }

  .table_hospit_attente ant-table-thead > th {
    background: transparent;
  }


  body {
    box-sizing: border-box;
    margin: auto 0;
  }

  * {
    font-family: "Poppins", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue",
    sans-serif;
  }

  .table {
    width: 100%;
    margin-top: 1rem;
    border: 1px solid #000;
    border-collapse: collapse;
    min-height: 100px;
    font-size: 20px;
  }

  .attente_row {
    background-color: #FFF;
  }

  .terminer_row {
    transition: text-decoration 0.3s ease;
    background-color: rgba(188, 41, 1, 0.31);
    font-weight: 600;
  }

  .report_row {
    transition: all 0.3s ease;
    background-color: #ffaf4050;
    text-decoration: line-through;
    opacity: 0.5;

    &:hover {
      transition: all 0.3s ease;
      opacity: 1;
    }
  }

  .annuler_row {
    transition: text-decoration 0.3s ease;
    background-color: #ff383850;
    text-decoration: line-through;
  }

  .rdv_row {
    cursor: pointer;
  }

  .ant-picker-header {
    background-color: #f86812;
    color: #FFF;

    button {
      color: #FFF;
    }
  }

  .ant-picker-footer {
    button {
      color: #f86812;
    }
  }

  .ant-picker-header {
    background-color: #f86812;
    color: #FFF;

    button {
      color: #FFF;
    }

    button:hover {
      color: #24247A;
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: #f86812;
    color: #FFF;
  }

  .ant-picker-content thead th {
    font-weight: bold;
    color: #f86812;
  }

  tr.ant-table-expanded-row > td {
    background-color: #FFF;
    z-index: 60;
    box-shadow: 0px 10px 10px #00000020;
  }

  .sortie_row {
    transition: text-decoration 0.3s ease;
    background-color: #ff383830;
  }

  .entree_row {
    transition: text-decoration 0.3s ease;
    background-color: #ff383850;
  }

  .row_controle {
    background: #ff000014;
  }

  .logo_span_text {
    display: none;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    // background: #10239e14;
    background: #f86812;
  }

  .ant-tabs-tab {
    // background: #10239e14;
    background: #cccccc;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .ant-tabs-tab-btn {
    padding: 0 10px;
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 1px solid #f86812;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    // background: #10239e14;
    color: #FFFFFF;
  }

  .ant-tabs-tabpane.ant-tabs-tabpane-active {
    background: #ecf2ff;
  }

  .ant-tabs-nav-list {
    background: #FFF;
  }

  @keyframes slide {
    0% {
      transform: translateX(-25%);
    }
    100% {
      transform: translateX(25%);
    }
  }
`;
