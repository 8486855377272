// Gestion des civilités
export const LISTE_CIVILITE = `${process.env.REACT_APP_API_URL}/params/liste-civilite`
export const AJOUTER_CIVILITE = `${process.env.REACT_APP_API_URL}/params/ajouter-civilite`
export const MODIFIER_CIVILITE = `${process.env.REACT_APP_API_URL}/params/modifier-civilite`
export const SUPPRIMER_CIVILITE = `${process.env.REACT_APP_API_URL}/params/supprimer-civilite`
export const MODIFIER_ETAT_CIVILITE = `${process.env.REACT_APP_API_URL}/params/modifier-etat-civilite`

// Gestion des période
export const LISTE_PERIODE = `${process.env.REACT_APP_API_URL}/params/liste-periode`
export const AJOUTER_PERIODE = `${process.env.REACT_APP_API_URL}/params/ajouter-periode`
export const MODIFIER_PERIODE = `${process.env.REACT_APP_API_URL}/params/modifier-periode`
export const SUPPRIMER_PERIODE = `${process.env.REACT_APP_API_URL}/params/supprimer-periode`
export const MODIFIER_ETAT_PERIODE = `${process.env.REACT_APP_API_URL}/params/modifier-etat-periode`

// Gestion des profils
export const CREATE_PROFIL_URL = `${process.env.REACT_APP_API_URL}/params/ajouter-profils`
export const UPDATE_PROFIL_URL = `${process.env.REACT_APP_API_URL}/params/modifier-profils`
export const GET_PROFILS_URL = `${process.env.REACT_APP_API_URL}/params/liste-profils`
export const DELETE_PROFILS_URL = `${process.env.REACT_APP_API_URL}/params/supprimer-profils`
export const MODIFIER_ETAT_PROFIL = `${process.env.REACT_APP_API_URL}/params/modifier-etat-profils`

// Gestion des profils Lea
export const CREATE_PROFIL_LEA_URL = `${process.env.REACT_APP_API_URL}/params/ajouter-profils-lea`
export const UPDATE_PROFIL_LEA_URL = `${process.env.REACT_APP_API_URL}/params/modifier-profils-lea`
export const GET_PROFILS_LEA_URL = `${process.env.REACT_APP_API_URL}/params/liste-profils-lea`
export const DELETE_PROFILS_LEA_URL = `${process.env.REACT_APP_API_URL}/params/supprimer-profils-lea`
export const MODIFIER_ETAT_LEA_PROFIL = `${process.env.REACT_APP_API_URL}/params/modifier-etat-profils-lea`

// Gestion des Categorie
export const CREATE_CATEGORIE_URL = `${process.env.REACT_APP_API_URL}/params/ajouter-categorie`
export const UPDATE_CATEGORIE_URL = `${process.env.REACT_APP_API_URL}/params/modifier-categorie`
// const GET_PROFIL_BY_ID = `${process.env.REACT_APP_API_URL}/params/select-user/`
export const GET_CATEGORIE_URL = `${process.env.REACT_APP_API_URL}/params/liste-categorie`
export const DELETE_CATEGORIE_URL = `${process.env.REACT_APP_API_URL}/params/supprimer-categorie`
export const MODIFIER_ETAT_CATEGORIE = `${process.env.REACT_APP_API_URL}/params/modifier-etat-categorie`

// Gestion des privilèges
export const LISTE_PRIVILEGE = `${process.env.REACT_APP_API_URL}/params/liste-privilege`
export const AJOUTER_PRIVILEGE = `${process.env.REACT_APP_API_URL}/params/ajouter-privilege`
export const MODIFIER_PRIVILEGE = `${process.env.REACT_APP_API_URL}/params/modifier-privilege`
export const SUPPRIMER_PRIVILEGE = `${process.env.REACT_APP_API_URL}/params/supprimer-privilege`
export const MODIFIER_ETAT_PRIVILEGE = `${process.env.REACT_APP_API_URL}/params/modifier-etat-privilege`

// Gestion des paramètres globaux
export const LISTE_PARAMS_GLOBAUX = `${process.env.REACT_APP_API_URL}/params/liste-parametre`
export const AJOUTER_PARAMS_GLOBAUX = `${process.env.REACT_APP_API_URL}/params/ajouter-parametre`
export const MODIFIER_PARAMS_GLOBAUX = `${process.env.REACT_APP_API_URL}/params/modifier-parametre`
export const SUPPRIMER_PARAMS_GLOBAUX = `${process.env.REACT_APP_API_URL}/params/supprimer-parametre`
export const MODIFIER_ETAT_PARAMS_GLOBAUX = `${process.env.REACT_APP_API_URL}/params/modifier-etat-parametre`

// Gestion des secteurs d'activités
export const LISTE_SECTEUR_ACTIVITE = `${process.env.REACT_APP_API_URL}/params/liste-secteur-activite`
export const AJOUTER_SECTEUR_ACTIVITE = `${process.env.REACT_APP_API_URL}/params/ajouter-secteur-activite`
export const MODIFIER_SECTEUR_ACTIVITE = `${process.env.REACT_APP_API_URL}/params/modifier-secteur-activite`
export const SUPPRIMER_SECTEUR_ACTIVITE = `${process.env.REACT_APP_API_URL}/params/supprimer-secteur-activite`
export const MODIFIER_ETAT_SECTEUR_ACTIVITE = `${process.env.REACT_APP_API_URL}/params/modifier-etat-secteur-activite`

// Gestion des banques
export const LISTE_BANQUE = `${process.env.REACT_APP_API_URL}/params/liste-banque`
export const AJOUTER_BANQUE = `${process.env.REACT_APP_API_URL}/params/ajouter-banque`
export const MODIFIER_BANQUE = `${process.env.REACT_APP_API_URL}/params/modifier-banque`
export const SUPPRIMER_BANQUE = `${process.env.REACT_APP_API_URL}/params/supprimer-banque`
export const MODIFIER_ETAT_BANQUE = `${process.env.REACT_APP_API_URL}/params/modifier-etat-banque`

// Gestion des unites
export const LISTE_UNITE = `${process.env.REACT_APP_API_URL}/params/liste-unite`
export const LISTE_UNITE_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-unite-active`
export const AJOUTER_UNITE = `${process.env.REACT_APP_API_URL}/params/ajouter-unite`
export const MODIFIER_UNITE = `${process.env.REACT_APP_API_URL}/params/modifier-unite`
export const SUPPRIMER_UNITE = `${process.env.REACT_APP_API_URL}/params/supprimer-unite`
export const MODIFIER_ETAT_UNITE = `${process.env.REACT_APP_API_URL}/params/modifier-etat-unite`

// Gestion des statuts
export const LISTE_STATUT = `${process.env.REACT_APP_API_URL}/params/liste-status-juridique`
export const AJOUTER_STATUT = `${process.env.REACT_APP_API_URL}/params/ajouter-status-juridique`
export const MODIFIER_STATUT = `${process.env.REACT_APP_API_URL}/params/modifier-status-juridique`
export const SUPPRIMER_STATUT = `${process.env.REACT_APP_API_URL}/params/supprimer-status-juridique`
export const MODIFIER_ETAT_STATUT = `${process.env.REACT_APP_API_URL}/params/modifier-etat-status-juridique`

// Gestion des statuts
export const LISTE_MODE_PAIEMENT = `${process.env.REACT_APP_API_URL}/params/liste-mode-de-paiement`
export const AJOUTER_MODE_PAIEMENT = `${process.env.REACT_APP_API_URL}/params/ajouter-mode-de-paiement`
export const MODIFIER_MODE_PAIEMENT = `${process.env.REACT_APP_API_URL}/params/modifier-mode-de-paiement`
export const SUPPRIMER_MODE_PAIEMENT = `${process.env.REACT_APP_API_URL}/params/supprimer-mode-de-paiement`
export const MODIFIER_ETAT_MODE_PAIEMENT = `${process.env.REACT_APP_API_URL}/params/modifier-etat-mode-de-paiement`

// Gestion des statuts
export const LISTE_OPERATEUR = `${process.env.REACT_APP_API_URL}/params/liste-operateur-mobile`
export const AJOUTER_OPERATEUR = `${process.env.REACT_APP_API_URL}/params/ajouter-operateur-mobile`
export const MODIFIER_OPERATEUR = `${process.env.REACT_APP_API_URL}/params/modifier-operateur-mobile`
export const SUPPRIMER_OPERATEUR = `${process.env.REACT_APP_API_URL}/params/supprimer-operateur-mobile`
export const MODIFIER_ETAT_OPERATEUR = `${process.env.REACT_APP_API_URL}/params/modifier-etat-operateur-mobile`

// Audit APP
export const LIST_AUDIT_APP = `${process.env.REACT_APP_API_URL}/user/liste-audit-app`

// Gestion Categorie de formule
export const LISTE_CATEGORIE_ABONNEMENT = `${process.env.REACT_APP_API_URL}/params/liste-categorie-abonnement`
export const LISTE_CATEGORIE_ABONNEMENT_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-categorie-abonnement-active`
export const AJOUTER_CATEGORIE_ABONNEMENT = `${process.env.REACT_APP_API_URL}/params/ajouter-categorie-abonnement`
export const MODIFIER_CATEGORIE_ABONNEMENT = `${process.env.REACT_APP_API_URL}/params/modifier-categorie-abonnement`
export const SUPPRIMER_CATEGORIE_ABONNEMENT = `${process.env.REACT_APP_API_URL}/params/supprimer-categorie-abonnement`
export const MODIFIER_ETAT_CATEGORIE_ABONNEMENT = `${process.env.REACT_APP_API_URL}/params/modifier-etat-categorie-abonnement`

// Gestion Categorie de formule
export const LISTE_FORMULE_ABONNEMENT = `${process.env.REACT_APP_API_URL}/params/liste-abonnement`
export const LISTE_FORMULE_ABONNEMENT_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-abonnement-active`
export const AJOUTER_FORMULE_ABONNEMENT = `${process.env.REACT_APP_API_URL}/params/ajouter-formule-abonnement`
export const MODIFIER_FORMULE_ABONNEMENT = `${process.env.REACT_APP_API_URL}/params/modifier-formule-abonnement`
export const SUPPRIMER_FORMULE_ABONNEMENT = `${process.env.REACT_APP_API_URL}/params/supprimer-formule-abonnement`
export const MODIFIER_ETAT_FORMULE_ABONNEMENT = `${process.env.REACT_APP_API_URL}/params/modifier-etat-formule-abonnement`

// Gestion Assertion
export const LISTE_ASSERTION = `${process.env.REACT_APP_API_URL}/params/liste-assertion`
export const LISTE_ASSERTION_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-assertion-active`
export const AJOUTER_ASSERTION = `${process.env.REACT_APP_API_URL}/params/ajouter-assertion`
export const MODIFIER_ASSERTION = `${process.env.REACT_APP_API_URL}/params/modifier-assertion`
export const SUPPRIMER_ASSERTION = `${process.env.REACT_APP_API_URL}/params/supprimer-assertion`
export const MODIFIER_ETAT_ASSERTION = `${process.env.REACT_APP_API_URL}/params/modifier-etat-assertion`

// Gestion Cycle
export const LISTE_CYCLE = `${process.env.REACT_APP_API_URL}/params/liste-cycle`
export const LISTE_CYCLE_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-cycle-active`
export const AJOUTER_CYCLE = `${process.env.REACT_APP_API_URL}/params/ajouter-cycle`
export const MODIFIER_CYCLE = `${process.env.REACT_APP_API_URL}/params/modifier-cycle`
export const SUPPRIMER_CYCLE = `${process.env.REACT_APP_API_URL}/params/supprimer-cycle`
export const MODIFIER_ETAT_CYCLE = `${process.env.REACT_APP_API_URL}/params/modifier-etat-cycle`

// Gestion Evenement
export const LISTE_EVENT = `${process.env.REACT_APP_API_URL}/params/liste-event`
export const LISTE_EVENT_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-event-active`
export const AJOUTER_EVENT = `${process.env.REACT_APP_API_URL}/params/ajouter-event`
export const MODIFIER_EVENT = `${process.env.REACT_APP_API_URL}/params/modifier-event`
export const SUPPRIMER_EVENT = `${process.env.REACT_APP_API_URL}/params/supprimer-event`
export const MODIFIER_ETAT_EVENT = `${process.env.REACT_APP_API_URL}/params/modifier-etat-event`

// Gestion Impact
export const LISTE_IMPACT = `${process.env.REACT_APP_API_URL}/params/liste-impact`
export const LISTE_IMPACT_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-impact-active`
export const AJOUTER_IMPACT = `${process.env.REACT_APP_API_URL}/params/ajouter-impact`
export const MODIFIER_IMPACT = `${process.env.REACT_APP_API_URL}/params/modifier-impact`
export const SUPPRIMER_IMPACT = `${process.env.REACT_APP_API_URL}/params/supprimer-impact`
export const MODIFIER_ETAT_IMPACT = `${process.env.REACT_APP_API_URL}/params/modifier-etat-impact`

// Gestion Niveau de risque
export const LISTE_NIVEAU = `${process.env.REACT_APP_API_URL}/params/liste-niveau`
export const LISTE_NIVEAU_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-niveau-active`
export const AJOUTER_NIVEAU = `${process.env.REACT_APP_API_URL}/params/ajouter-niveau`
export const MODIFIER_NIVEAU = `${process.env.REACT_APP_API_URL}/params/modifier-niveau`
export const SUPPRIMER_NIVEAU = `${process.env.REACT_APP_API_URL}/params/supprimer-niveau`
export const MODIFIER_ETAT_NIVEAU = `${process.env.REACT_APP_API_URL}/params/modifier-etat-niveau`

// Gestion Probabilite
export const LISTE_PROBABILITE = `${process.env.REACT_APP_API_URL}/params/liste-probabilite`
export const LISTE_PROBABILITE_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-probabilite-active`
export const AJOUTER_PROBABILITE = `${process.env.REACT_APP_API_URL}/params/ajouter-probabilite`
export const MODIFIER_PROBABILITE = `${process.env.REACT_APP_API_URL}/params/modifier-probabilite`
export const SUPPRIMER_PROBABILITE = `${process.env.REACT_APP_API_URL}/params/supprimer-probabilite`
export const MODIFIER_ETAT_PROBABILITE = `${process.env.REACT_APP_API_URL}/params/modifier-etat-probabilite`

// Gestion risque
export const LISTE_RISQUE = `${process.env.REACT_APP_API_URL}/params/liste-risque`
export const LISTE_RISQUE_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-risque-active`
export const AJOUTER_RISQUE = `${process.env.REACT_APP_API_URL}/params/ajouter-risque`
export const MODIFIER_RISQUE = `${process.env.REACT_APP_API_URL}/params/modifier-risque`
export const SUPPRIMER_RISQUE = `${process.env.REACT_APP_API_URL}/params/supprimer-risque`
export const MODIFIER_ETAT_RISQUE = `${process.env.REACT_APP_API_URL}/params/modifier-etat-risque`

// Gestion sommaire
export const LISTE_SOMMAIRE = `${process.env.REACT_APP_API_URL}/params/liste-sommaire`
export const LISTE_SOMMAIRE_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-sommaire-active`
export const AJOUTER_SOMMAIRE = `${process.env.REACT_APP_API_URL}/params/ajouter-sommaire`
export const MODIFIER_SOMMAIRE = `${process.env.REACT_APP_API_URL}/params/modifier-sommaire`
export const SUPPRIMER_SOMMAIRE = `${process.env.REACT_APP_API_URL}/params/supprimer-sommaire`
export const MODIFIER_ETAT_SOMMAIRE = `${process.env.REACT_APP_API_URL}/params/modifier-etat-sommaire`

// Gestion intervention
export const LISTE_INTERVENTION = `${process.env.REACT_APP_API_URL}/params/liste-intervention`
export const LISTE_INTERVENTION_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-intervention-active`
export const AJOUTER_INTERVENTION = `${process.env.REACT_APP_API_URL}/params/ajouter-intervention`
export const MODIFIER_INTERVENTION = `${process.env.REACT_APP_API_URL}/params/modifier-intervention`
export const SUPPRIMER_INTERVENTION = `${process.env.REACT_APP_API_URL}/params/supprimer-intervention`
export const MODIFIER_ETAT_INTERVENTION = `${process.env.REACT_APP_API_URL}/params/modifier-etat-intervention`

// Gestion programme
export const LISTE_PROGRAMME = `${process.env.REACT_APP_API_URL}/params/liste-programme`
export const LISTE_PROGRAMME_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-programme-active`
export const AJOUTER_PROGRAMME = `${process.env.REACT_APP_API_URL}/params/ajouter-programme`
export const MODIFIER_PROGRAMME = `${process.env.REACT_APP_API_URL}/params/modifier-programme`
export const SUPPRIMER_PROGRAMME = `${process.env.REACT_APP_API_URL}/params/supprimer-programme`
export const MODIFIER_ETAT_PROGRAMME = `${process.env.REACT_APP_API_URL}/params/modifier-etat-programme`

// Gestion question
export const LISTE_QUESTION = `${process.env.REACT_APP_API_URL}/params/liste-question`
export const LISTE_QUESTION_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-question-active`
export const AJOUTER_QUESTION = `${process.env.REACT_APP_API_URL}/params/ajouter-question`
export const MODIFIER_QUESTION = `${process.env.REACT_APP_API_URL}/params/modifier-question`
export const SUPPRIMER_QUESTION = `${process.env.REACT_APP_API_URL}/params/supprimer-question`
export const MODIFIER_ETAT_QUESTION = `${process.env.REACT_APP_API_URL}/params/modifier-etat-question`

// Gestion tache
export const LISTE_TACHE = `${process.env.REACT_APP_API_URL}/params/liste-tache`
export const LISTE_TACHE_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-tache-active`
export const AJOUTER_TACHE = `${process.env.REACT_APP_API_URL}/params/ajouter-tache`
export const MODIFIER_TACHE = `${process.env.REACT_APP_API_URL}/params/modifier-tache`
export const SUPPRIMER_TACHE = `${process.env.REACT_APP_API_URL}/params/supprimer-tache`
export const MODIFIER_ETAT_TACHE = `${process.env.REACT_APP_API_URL}/params/modifier-etat-tache`

// Gestion facture
export const LISTE_FACTURE = `${process.env.REACT_APP_API_URL}/facture/liste-des-factures`
export const LISTE_PAIEMENT_FACTURE = `${process.env.REACT_APP_API_URL}/facture/liste-des-paiements-facture/:facture_id`
// export const LISTE_TACHE_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-tache-active`
// export const AJOUTER_TACHE = `${process.env.REACT_APP_API_URL}/params/ajouter-tache`
// export const MODIFIER_TACHE = `${process.env.REACT_APP_API_URL}/params/modifier-tache`
// export const SUPPRIMER_TACHE = `${process.env.REACT_APP_API_URL}/params/supprimer-tache`
// export const MODIFIER_ETAT_TACHE = `${process.env.REACT_APP_API_URL}/params/modifier-etat-tache`

// Gestion paiement
export const LISTE_PAIEMENT = `${process.env.REACT_APP_API_URL}/facture/liste-des-paiements`
// export const LISTE_TACHE_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-tache-active`
// export const AJOUTER_TACHE = `${process.env.REACT_APP_API_URL}/params/ajouter-tache`
// export const MODIFIER_TACHE = `${process.env.REACT_APP_API_URL}/params/modifier-tache`
// export const SUPPRIMER_TACHE = `${process.env.REACT_APP_API_URL}/params/supprimer-tache`
// export const MODIFIER_ETAT_TACHE = `${process.env.REACT_APP_API_URL}/params/modifier-etat-tache`

export const DOWNLOAD_FILE_CLIENT = `${process.env.REACT_APP_API_URL}/files`

export const API_URL = process.env.REACT_APP_API_URL