import axios from 'axios'
import { USER_ACTION } from "./action";

/* Begin::GET ACTION*/
export const getAllUsers = async () => {
  return axios
    .get(USER_ACTION.GET_LIST_All)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'Un problème est survenu! Veillez réessayer!',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const getActiveUsers = async () => {
  return axios
    .get(USER_ACTION.GET_LISTE_ACTIVE)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'Un problème est survenu! Veillez réessayer!',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const getDetails = async (id) => {
  return axios
    .put(`${USER_ACTION.GET_DETAIL_URL}/${id}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'Un problème est survenu! Veillez réessayer!',
        }
      }
    })
    .catch((err) => console.log(err))
}
/* End::GET ACTION*/

/* Begin::POST ACTION*/
export const userRegister = async (data) => {
  return axios
    .post(USER_ACTION.CREATE_URL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'Un problème est survenu! Veillez réessayer!',
        }
      }
    })
    .catch((err) => console.log(err))
}
/* End::POST ACTION*/

/* Begin::PUT AND PATCH ACTION*/
export const userUpdate = async (id, data) => {
  return axios
    .put(`${USER_ACTION.EDITED_URL}/${id}`, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'Un problème est survenu! Veillez réessayer!',
        }
      }
    })
    .catch((err) => console.log(err))
}
export const userUpdateState = async (id, data) => {
  return axios
    .put(`${USER_ACTION.UPDATE_STATE_URL}/${id}`, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'Un problème est survenu! Veillez réessayer!',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const userUpdatePassword = async (id, data) => {
  return axios
    .put(`${USER_ACTION.UPDATE_PASSWORD_URL}/${id}`, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'Un problème est survenu! Veillez réessayer!',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const userGeneratePassword = async (id, data) => {
  return axios
    .put(`${USER_ACTION.GENERATE_PASSWORD_URL}/${id}`, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'Un problème est survenu! Veillez réessayer!',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const deleteUser = async (id, data) => {
  return axios
    .put(`${USER_ACTION.DELETE_URL}/${id}`, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'Un problème est survenu! Veillez réessayer!',
        }
      }
    })
    .catch((err) => console.log(err))
}
/* End::PUT AND PATCH ACTION*/

/* Begin::DESTROY ACTION*/
export const deleteConfirm = async (id, data) => {
  return axios
    .put(`${USER_ACTION.DESTROY_URL}/${id}`, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'Un problème est survenu! Veillez réessayer!',
        }
      }
    })
    .catch((err) => console.log(err))
}
/* Begin::DESTROY ACTION*/
