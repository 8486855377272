import axios from 'axios'
import {AuthModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const LOGIN_URL = `${API_URL}/auth/login`

// Server should return AuthModel
export function login(login: string, password: string) {
  return axios
    .post<AuthModel>(LOGIN_URL, {
      login,
      password,
    })
    .catch((error) => {
      throw new Error(error.message)
    })
}