import React from 'react'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useAuth} from '../../auth'
import {PERMISSION, toAbsoluteUrl} from '../../../helpers'
import {Link} from 'react-router-dom'
import {HomeFilled} from '@ant-design/icons'
import clsx from 'clsx'

export function AsideMenuMain() {
  const {currentUser} = useAuth()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title='Tableau de bord'
        fontIcon='bi-app-indicator'
      />
      {/*begin::ACCOUNT*/}
      <div className='menu-item'>
        <Link
          className='sub-link menu-link without-sub'
          to={`/apps/utilisateurs/profil/${currentUser?.id}}`}
          state={{user: currentUser}}
        >
          <HomeFilled style={{fontSize: '25px', color: '#f86812', paddingRight: '10px'}} />
          <span className='menu-title'>Mon Profil</span>
        </Link>
      </div>
      {/*end::ACCOUNT*/}

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-dark fw-boldest text-uppercase fs-8 ls-1'>
            APPLICATION
          </span>
        </div>
      </div>

      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>

      {/*begin::USERS*/}

      <AsideMenuItemWithSub
        to='/apps/utilisateurs'
        title='Gestion Utilisateurs'
        fontIcon='bi bi-people'
        icon='/media/icons/duotune/communication/com014.svg'
      >
        <AsideMenuItem
          to='/apps/utilisateurs/liste'
          title='Liste Utilisateurs'
          hasBullet={true}
        />
        <AsideMenuItem to='/apps/utilisateurs/ajout' title='Enregistrer' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/*end::USERS*/}

      {/*begin::SOUSCRIPTION*/}
      <AsideMenuItem
        to='/apps/souscriptions/liste'
        title='Formules Souscription'
        fontIcon='bi-layer'
        icon='/media/icons/duotune/ecommerce/ecm002.svg'
      />
      {/*end::SOUSCRIPTION*/}
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-dark fw-boldest text-uppercase fs-8 ls-1'>
              Paramétrages
            </span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/parametres'
        title='Parametres'
        fontIcon='bi bi-gear'
        icon='/media/icons/duotune/general/gen051.svg'
      >
        <AsideMenuItem
          to='/apps/parametres/civilite'
          title='Civilités'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/apps/parametres/pays'
          title='Pays'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/apps/parametres/profils'
          title='Profils utilisateurs'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/apps/parametres/roles'
          title='Roles du bureau'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/apps/parametres/type-abonnements'
          title="Type d'abonnement"
          hasBullet={true}
        />
      </AsideMenuItemWithSub>

      {/*begin::CLIENTS*/}
      {/*<AsideMenuItemWithSub*/}
      {/*  to='/apps/clients'*/}
      {/*  title='Clients'*/}
      {/*  fontIcon='bi-layer'*/}
      {/*  icon='/media/icons/duotune/finance/fin006.svg'*/}
      {/*>*/}
      {/*  <AsideMenuItem to='/apps/clients/ajout' title='Enregistrer' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/apps/clients/liste' title='Liste clients' hasBullet={true} />*/}
      {/*</AsideMenuItemWithSub>*/}
      {/*end::CLIENTS*/}

      {/*begin::Fcature*/}

      {/*{currentUser &&*/}
      {/*  (currentUser.libelleProfil === PERMISSION.level_1 ||*/}
      {/*    currentUser.libelleProfil === PERMISSION.level_2) && (*/}
      {/*    <>*/}
      {/*      <div className='menu-item'>*/}
      {/*        <div className='menu-content pt-8 pb-2'>*/}
      {/*          <span className='menu-section text-dark fw-boldest text-uppercase fs-8 ls-1'>*/}
      {/*            Paramétrages*/}
      {/*          </span>*/}
      {/*        </div>*/}
      {/*      </div>*/}

      {/*      <div className='menu-item'>*/}
      {/*        <div className='menu-content'>*/}
      {/*          <div className='separator mx-1 my-4'></div>*/}
      {/*        </div>*/}
      {/*      </div>*/}

      {/*      <AsideMenuItemWithSub*/}
      {/*        to='/pages'*/}
      {/*        title='Paramètres'*/}
      {/*        fontIcon='bi bi-gear'*/}
      {/*        icon='/media/icons/duotune/general/gen051.svg'*/}
      {/*      >*/}
      {/*        <AsideMenuItem*/}
      {/*          to='/apps/parametres/secteurs-activites'*/}
      {/*          title='Secteur activité'*/}
      {/*          hasBullet={true}*/}
      {/*        />*/}
      {/*        */}
      {/*        <AsideMenuItem*/}
      {/*          to='/apps/parametres/normes-isa'*/}
      {/*          title='Normes ISA'*/}
      {/*          hasBullet={true}*/}
      {/*        />*/}
      {/*        <AsideMenuItem*/}
      {/*          to='/apps/parametres/statut'*/}
      {/*          title='Statut Juridique'*/}
      {/*          hasBullet={true}*/}
      {/*        />*/}
      {/*        <AsideMenuItem to='/apps/parametres/civilites' title='Civilités' hasBullet={true} />*/}
      {/*        <AsideMenuItem to='/apps/parametres/pays' title='Pays' hasBullet={true} />*/}
      {/*        <AsideMenuItem to='/apps/parametres/periodes' title='Périodes' hasBullet={true} />*/}
      {/*        <AsideMenuItem*/}
      {/*          to='/apps/parametres/unites'*/}
      {/*          title='Unité de parametre'*/}
      {/*          hasBullet={true}*/}
      {/*        />*/}
      {/*        <AsideMenuItem to='/apps/parametres/categories' title='categories' hasBullet={true} />*/}
      {/*        <AsideMenuItem to='/apps/users/profils' title='Profils' hasBullet={true} />*/}
      {/*        <AsideMenuItem to='/apps/users/profils-lea' title='Profils LEA' hasBullet={true} />*/}
      {/*        <AsideMenuItem to='/apps/parametres/privileges' title='Privilèges' hasBullet={true} />*/}
      {/*        <AsideMenuItem to='/apps/parametres/banques' title='Banques' hasBullet={true} />*/}
      {/*        <AsideMenuItem*/}
      {/*          to='/apps/parametres/mode-de-paiement'*/}
      {/*          title='Modes de paiement'*/}
      {/*          hasBullet={true}*/}
      {/*        />*/}

      {/*        <AsideMenuItem*/}
      {/*          to='/apps/parametres/operateur-mobile'*/}
      {/*          title='Opérateur mobile'*/}
      {/*          hasBullet={true}*/}
      {/*        />*/}
      {/*        <AsideMenuItem*/}
      {/*          to='/apps/parametres/globaux'*/}
      {/*          title='Paramètres globaux'*/}
      {/*          hasBullet={true}*/}
      {/*        />*/}

      {/*        <AsideMenuItem*/}
      {/*          to='/apps/parametres/categorie-formule'*/}
      {/*          title='Categorie de formule'*/}
      {/*          hasBullet={true}*/}
      {/*        />*/}
      {/*        <AsideMenuItem to='/apps/parametres/cycle' title='Cycle' hasBullet={true} />*/}
      {/*        <AsideMenuItem*/}
      {/*          to='/apps/parametres/rubrique-cycle'*/}
      {/*          title='Rubrique de cycle'*/}
      {/*          hasBullet={true}*/}
      {/*        />*/}
      {/*        <AsideMenuItem*/}
      {/*          to='/apps/parametres/questions-cycle'*/}
      {/*          title='Questions sources de cycle'*/}
      {/*          hasBullet={true}*/}
      {/*        />*/}
      {/*        <AsideMenuItem to='/apps/parametres/assertion' title='Assertion' hasBullet={true} />*/}
      {/*        <AsideMenuItem*/}
      {/*          to='/apps/parametres/evenement-source-de-risque'*/}
      {/*          title='Evenement source de risque'*/}
      {/*          hasBullet={true}*/}
      {/*        />*/}
      {/*        <AsideMenuItem*/}
      {/*          to='/apps/parametres/impact_financier'*/}
      {/*          title='Impact financier'*/}
      {/*          hasBullet={true}*/}
      {/*        />*/}
      {/*        <AsideMenuItem*/}
      {/*          to='/apps/parametres/niveau_de_risque'*/}
      {/*          title='Niveau de risque'*/}
      {/*          hasBullet={true}*/}
      {/*        />*/}
      {/*        <AsideMenuItem*/}
      {/*          to='/apps/parametres/probabilite_de_sourvenance'*/}
      {/*          title='Probabilité de survenance'*/}
      {/*          hasBullet={true}*/}
      {/*        />*/}
      {/*        <AsideMenuItem*/}
      {/*          to='/apps/parametres/risque_inherent'*/}
      {/*          title='Risque inherent'*/}
      {/*          hasBullet={true}*/}
      {/*        />*/}
      {/*        <AsideMenuItem to='/apps/parametres/sommaire' title='Sommaire' hasBullet={true} />*/}
      {/*        <AsideMenuItem*/}
      {/*          to='/apps/parametres/utilisateurs'*/}
      {/*          title='Utilisateurs'*/}
      {/*          hasBullet={true}*/}
      {/*        />*/}
      {/*        <AsideMenuItem*/}
      {/*          to='/apps/parametres/intervention'*/}
      {/*          title='Intervention'*/}
      {/*          hasBullet={true}*/}
      {/*        />*/}
      {/*        <AsideMenuItem*/}
      {/*          to='/apps/parametres/gestion_equipe_exercice'*/}
      {/*          title='Gestion equipe exercice'*/}
      {/*          hasBullet={true}*/}
      {/*        />*/}
      {/*        <AsideMenuItem*/}
      {/*          to='/apps/parametres/tache_autorisee'*/}
      {/*          title='Tâches autorisées'*/}
      {/*          hasBullet={true}*/}
      {/*        />*/}
      {/*        <AsideMenuItem*/}
      {/*          to='/apps/parametres/questionnaire_general'*/}
      {/*          title='Questionnaire general'*/}
      {/*          hasBullet={true}*/}
      {/*        />*/}
      {/*        /!*<AsideMenuItem*!/*/}
      {/*        /!*  to='/apps/parametres/programme_de_travail'*!/*/}
      {/*        /!*  title='Programmes de travail'*!/*/}
      {/*        /!*  hasBullet={true}*!/*/}
      {/*        /!*//*/}
      {/*        <AsideMenuItem*/}
      {/*          to='/apps/facture/facturations'*/}
      {/*          title='Liste de facture'*/}
      {/*          hasBullet={true}*/}
      {/*        />*/}
      {/*        <AsideMenuItem*/}
      {/*          to='/apps/parametres/gestion_equipe_exercice'*/}
      {/*          title='Gestion equipe exercice'*/}
      {/*          hasBullet={true}*/}
      {/*        />*/}
      {/*        <AsideMenuItem*/}
      {/*          to='/apps/parametres/tache_autorisee'*/}
      {/*          title='Tâches autorisées'*/}
      {/*          hasBullet={true}*/}
      {/*        />*/}
      {/*        <AsideMenuItem*/}
      {/*          to='/apps/parametres/questionnaire_general'*/}
      {/*          title='Questionnaire general'*/}
      {/*          hasBullet={true}*/}
      {/*        />*/}
      {/*        <AsideMenuItem*/}
      {/*          to='/apps/parametres/programme_de_travail'*/}
      {/*          title='Programmes de travail'*/}
      {/*          hasBullet={true}*/}
      {/*        />*/}
      {/*        <AsideMenuItem*/}
      {/*          to='/apps/parametres/programme_audit_initial'*/}
      {/*          title="Programmes d'audit initial"*/}
      {/*          hasBullet={true}*/}
      {/*        />*/}
      {/*        <AsideMenuItem*/}
      {/*          to='/apps/parametres/programme_travaux_professionnel'*/}
      {/*          title="Programmes travaux d'autres professionnel"*/}
      {/*          hasBullet={true}*/}
      {/*        />*/}
      {/*        <AsideMenuItem*/}
      {/*          to='/apps/parametres/programme_verification_specifique_sa_sarl'*/}
      {/*          title="Programmes Vérification spécifique SA/SARL"*/}
      {/*          hasBullet={true}*/}
      {/*        />*/}
      {/*      </AsideMenuItemWithSub>*/}

      {/*      {(currentUser?.id === 25 || currentUser?.id === 15 || currentUser?.id === 27) && (*/}
      {/*        <AsideMenuItem*/}
      {/*          to='/apps/audit-app'*/}
      {/*          icon='/media/icons/duotune/art/art002.svg'*/}
      {/*          title='Audit App'*/}
      {/*          fontIcon='bi-app-indicator'*/}
      {/*        />*/}
      {/*      )}*/}

      {/*      <div className='sidebar-footer'>*/}
      {/*        <span*/}
      {/*          className='d-flex p-5 align-items-center flex-wrap m-1'*/}
      {/*          style={{borderRadius: '10px', backgroundColor: 'rgba(209,236,89,0.3)'}}*/}
      {/*        >*/}
      {/*          <div className='symbol symbol-circle symbol-48px overflow-hidden me-3'>*/}
      {/*            {currentUser?.avatar ? (*/}
      {/*              <div className='symbol-label'>*/}
      {/*                <img*/}
      {/*                  src={toAbsoluteUrl(`/media/${currentUser.avatar}`)}*/}
      {/*                  alt={currentUser.nom_user + ' ' + currentUser.prenom_user}*/}
      {/*                  className='w-100'*/}
      {/*                />*/}
      {/*              </div>*/}
      {/*            ) : currentUser?.codeCivilite === 'M.' ? (*/}
      {/*              <div className={clsx('symbol-label fs-3', `bg-light-green`)}>*/}
      {/*                <img*/}
      {/*                  src={toAbsoluteUrl('/media/avatars/male.jpg')}*/}
      {/*                  alt={currentUser.nom_user + ' ' + currentUser.prenom_user}*/}
      {/*                  className='w-100'*/}
      {/*                />*/}
      {/*              </div>*/}
      {/*            ) : (*/}
      {/*              <div className={clsx('symbol-label fs-3', `bg-light-green`)}>*/}
      {/*                <img*/}
      {/*                  src={toAbsoluteUrl('/media/avatars/female.jpg')}*/}
      {/*                  alt={currentUser?.nom_user + ' ' + currentUser?.prenom_user}*/}
      {/*                  className='w-100'*/}
      {/*                />*/}
      {/*              </div>*/}
      {/*            )}*/}
      {/*          </div>*/}
      {/*          <div className='d-flex flex-column'>*/}
      {/*            <span className='fs-25' style={{fontWeight: '700', letterSpacing: '.75'}}>*/}
      {/*              {currentUser?.nom_user + ' ' + currentUser?.prenom_user}.*/}
      {/*            </span>*/}
      {/*            <span className=' fs-15 lh-2' style={{fontWeight: '600', letterSpacing: '.86'}}>*/}
      {/*              {currentUser?.libelleProfil}*/}
      {/*            </span>*/}
      {/*          </div>*/}
      {/*        </span>*/}
      {/*      </div>*/}
      {/*    </>*/}
      {/*  )}*/}
    </>
  )
}
