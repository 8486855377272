import { API_URL } from "../../api/apiRoute";

export const USER_ACTION = {
  GET_LIST_All: `${API_URL}/user/liste`,
  GET_LISTE_ACTIVE: `${API_URL}/user/liste-active`,
  GET_DETAIL_URL : `${API_URL}/user/details`,
  CREATE_URL: `${API_URL}/auth/register`,
  EDITED_URL: `${API_URL}/user/modifier`,
  UPDATE_STATE_URL: `${API_URL}/user/modifier-etat`,
  GENERATE_PASSWORD_URL: `${API_URL}/user/generer-password`,
  UPDATE_PASSWORD_URL: `${API_URL}/user/modifier-password`,
  DELETE_URL: `${API_URL}/user/supprimer`,
  DESTROY_URL: `${API_URL}/user/detruire`,
}